/* ###################### Profile Image ############################################# */
.profile_image, .profile_image_emp{background-color:#ededed; border: solid 1px $border-color; border-radius: .25rem; padding: 0.475rem;
	figure{background:#f6f7f9; padding:0; margin: 0;  border: solid 1px $border-color; position: relative; max-width: 60px; max-height: 60px; overflow: hidden;
			img{position: relative; z-index: 1;}
			a.remove-profile-img{position: absolute; top: 0px; right: 0px; background: $white; color: $default; border-radius: 50%;
				width: 20px; height: 20px; line-height:18px; display: none; z-index: 9; text-decoration: none; outline: none;
				text-align: center; border: solid 0.12rem $default; 
				i{font-size: 1.05rem;}
			}
			a.zoom-profile-img{position: absolute; color: $white; display: none; z-index: 10; text-decoration: none; outline: none; top: 50%; left: 50%; transform: translate(-50%,-50%);;
			}
		}
		.btn i{top: 50%; left: 50%; transform: translate(-50%,-50%); position: absolute; font-size: 1rem; line-height: 1; opacity: 0.7;}
		.btn-file .btn, .btn-edit .btn{width:2.25rem;  height:2.25rem; margin-bottom: 0.25rem;  position: relative; border: solid 1px #717171; border-radius: 50%; cursor: pointer; background: $bg_card;}
		.btn-file{text-align: center;}
		.btn-edit { display: none;}

		&.hasprofileImg{
			.btn-file{display: none;}
			.btn-edit{display: block;}
			figure:hover{
				a.remove-profile-img, .zoom-profile-img{display: block;}
			}
		}

	p{line-height: 1.2; margin: 0;}
	.confirm-dialog-box{right: 0.4rem; bottom:1.05rem; min-width: 270px;}
	.confirm-dialog-box-Emp{right: 0.4rem; bottom:1.05rem; min-width: 270px;}
}

/* ################ Upload Employee Card ############################*/
.emp-card{padding: 0.75rem;
	figure{border: solid 1px $border-color; border-radius: 0.25rem; overflow: hidden;}
}

/* ######################### Registration Date ####################################### */
.registration-date{
	input{border: none; outline: none; max-width: 175px; text-align: center;
		&:focus, &:active, &:hover{border: none; outline: none;}
	}
}

/* ######################## Aadhar Card  ####################################### */
.aadhar_action{
	.otpok {position:relative; color:$success; top: 0.3rem; right: 0; font-size: 1.6rem; }
	.get_otp{
			.otp-input {margin-right: 1rem; text-align: center; width:79px; font-size: 1.2rem; border: none; outline: none; height: calc(2rem + 0px); padding: 3px; margin: 0px; 
				border-bottom: solid 0.2rem #cc3300 !important; 
			   &:focus, &:active{border: none; outline:none;}
		}
		.timer{width: 26px; height: 26px; border-radius: 50%; background: $bg_card; display: inline-block; line-height: 9px; display: none;
		   font-size: 0.7rem; text-align: center; 	position: relative; top: 3px;
		   strong{color: $primary; display: inline-block; padding-top: 5px;}
		   small{display: inline-block;}
	   }
	   .resend_otp{display: none; border: none; background: none; 	color:$primary; position: relative; top: 5px;
		   &:focus, &:active{border: none; outline:none;}
	   }
   }

}



/* ######################## Duplicate Patient  ####################################### */
.duplicate_patient{position: absolute; top: 0; width: 90%; max-height: calc(100vh - 400px);   box-shadow:0 4px 16px 4px #d2435040; border-radius: 0.25rem; z-index: 1000; transform: translateX(5%);
	.card_wrap{position: relative; background-color: $white;width: 100%;
		.card-body{height:340px; overflow-y:auto; padding:0px;}
		.card{border: none; border-radius: 0;
			&:not(:first-child){border-top: solid 1px $border-color;}
			.profile-image{width: 42px; height: 42px; border-radius: 50%;}
		}
	}
}

#modal_staff_details{
	#employee_code{min-width: 300px;}
	#staff_details{display: none;}
	.staff_dependent{border: none!important; padding: 0!important;}
	.new_dependent_frm, .new_staff_UHID_list{display: none;}
}

/* ################# Staff Patient ############################# */
.form-control.matched{color: $success;}
.form-control.notmatched{color: $primary;}
.staff_popover{
	&.matched{border-color: $success; 
		p span{color: $success;}
		.arrow:before{border-bottom-color:$success; border-top-color:$success;}
	}
	&.notmatched{border-color: $primary; 
		p span{color: $primary;}
		.arrow:before{border-bottom-color:$primary; border-top-color:$primary;}
	}
	p{margin: 0; padding: 0;}
}

#kin_name + .gselect-bg-grey{min-width:120px}