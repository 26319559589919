.row{margin-right: -$gutter; margin-left: -$gutter;}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto{padding-left:$gutter; padding-right:$gutter}
.col-10p{width:10.25%}
#main{padding-left: 50px; min-height: calc(100vh - 122px); position: relative;}
.container-fluid{position: relative; padding-right: 10px; padding-left: 10px; min-height: inherit;}
@media (min-width: 1680px){.container-fluid{ box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.06);}}
.fixed-cta{position: fixed; background: $white; left: 0; bottom: 0; width: 100%; z-index: 999;
    .container-fluid{padding:1rem 10px 1rem 60px; box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 4px 1px;}
}
.p-3{padding: 0.75rem!important;}
.px-3{padding-left: 0.75rem!important; padding-right: 0.75rem!important;}

/** SCROLL BAR CSS **/
 body {overflow: hidden; overflow-y: auto;}
 *::-webkit-scrollbar {  scrollbar-width: auto; width:10px; height:10px;
 }
 *::-webkit-scrollbar-track {background: #fafafa;}
 *::-webkit-scrollbar-thumb {background-color: #999; border-radius: 8px;}
 *::-webkit-scrollbar-thumb:hover {background: #5b5b5b;cursor: pointer}
::-webkit-scrollbar-track
{

	border-radius: 0px!important;
	background-color: white;
}

::-webkit-scrollbar
{
	border-radius: 0px!important;
	background-color: white;
}

::-webkit-scrollbar-thumb{
border-radius: 0px!important;
}



//  *::-webkit-scrollbar-thumb {background-color: #999; border-radius: 8px;}



.padding_less{padding: 3px 0; margin-top: 4px !important; margin-bottom: 4px !important;}
.custom-checkbox .custom-control-input{z-index: 30;}
.cke_bottom{background: none !important; border: none !important;}
.slash_input::after{top:-7px !important; position: absolute;}
.table_layout_popup{table-layout: fixed;}
.table_layout_popup td{word-break: break-all;}
.ant-radio-inner{width: 16px !important; height: 16px !important; border-radius: 16px !important; display: block; }
.ant-radio-inner:after{
	background: url(../images/radio-on.png) center/16px 16px transparent no-repeat !important;
    width: 16px!important;
    height: 16px!important;
	background-color:transparent!important;
    border: 0!important;
    background-size: contain;
    background-repeat: no-repeat;
    top: 7px!important;
	left: 7px!important;
    transform:scale(1)!important;

}
.search_text_suggession{width: calc(100% - 30px); display: inline-block; vertical-align: top; overflow: hidden; word-break: break-all; white-space: pre-wrap;}
.ant-select-item-option{min-height: 44px; height: auto !important;}
.remove_margin table{margin-bottom: 0;}
.active_medication_new{height: 146px; overflow-y: scroll;}
.margin_tp{margin-top: 5px !important;}
.ant-select-item-option-content .desc{width: calc(100% - 160px); display: inline-block; vertical-align: top; overflow: hidden; word-break: break-all; white-space: pre-wrap;}





